<template>
  <div class="_w-max _pc-pad-y-70 _mb-pad-y-60  _flex-col _pc-font-16 _mb-font-24">
<!--    精选评价-->
    <div class="_w-max _pc-pad-x-260 _mb-pad-x-30 wow fadeInUp">
      <div class="_w-max">
        <!--      title标题-->
        <div class="featured-left-title _pc-font-16 _flex-row-end">
          <div class="_pc-font-42 _mb-font-52" style="color:var(--theme-color-title);font-weight: 800;line-height: 0.9;">FEATURED</div><div class="_pc-font-28 _mb-font-36 _pc-pad-left-20 _mb-pad-left-25" style="font-weight: 800;">精选评价</div>
        </div>

        <div class="_flex-row _pc-pad-y-50 _mb-pad-y-30 _mb-flex-col">
          <img v-for="(item,index) in pc_avaluation_banner" class="_pc-w-660 _mb-w-max _mb-order-1" :src="item.image_url"  v-show="item.name=='pc_featured_img'" :key="index">
          <div class="_flex-1 _pc-pad-y-20 _mb-pad-y-30 _pc-pad-x-50 mb-pad-x-25 _flex-col-bet" style="background-color: #f7f7f7;position: relative;" v-if="featured[0]">
            <div class="_flex-col">
              <div class="_flex-row-mid _pc-mar-bottom-20 _mb-pad-bottom-5 _mb-display-block">
              <div class="_mb-flex-row-mid _mb-mar-bottom-20">
                <img class="_pc-w-60 _mb-w-60 _border-circle" style="" :src="featured[featured_index].headimgurl" alt="">
                <span class="_pc-font-26 _mb-font-32 _mb-mar-left-20 _pc-display-none">{{ featured[featured_index].nickname}}</span>
              </div>
              <div class="_pc-mar-left-20 _mb-mar-left-0 _pc-font-16 _mb-font-24 _flex-col-center">
                <span class="_pc-font-26 _pc-pad-bottom-10 _mb-pad-bottom-5 _mb-display-none">{{ featured[featured_index].nickname}}</span>
                <span class="_w-max _mb-display-none">
                  <span>订单号: {{ featured[featured_index].ordersn }}</span>
                  <span class="_pc-pad-left-20 _mb-pad-left-60">路线: {{ featured[featured_index].area_express_name }}</span>
                </span>
              </div>
            </div>
            <div class="_pc-font-16 _mb-font-24 _flex-wrap _flex-row _pc-mar-y-10 _mb-mar-bottom-30 comm-bg _mb-pad-y-30">
              <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid" >
                <div class="_mb-w-max _text-center _mb-mar-bottom-20">客服服务:</div> 
                <div class="_mb-flex-row-mid">
                  <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="star_index<featured[featured_index].service_score?stars_t:stars_f" :alt="item_star">
                </div>
              </div>
              <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid" >
                <div class="_mb-w-max _text-center _mb-mar-bottom-20">物流速度:</div> 
                <div class="_mb-flex-row-mid">
                  <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="star_index<featured[featured_index].express_score?stars_t:stars_f" :alt="item_star">
                </div>
              </div>
              <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid" >
                <div class="_mb-w-max _text-center _mb-mar-bottom-20">包裹完整:</div> 
                <div class="_mb-flex-row-mid">
                  <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="star_index<featured[featured_index].pack_score?stars_t:stars_f" :alt="item_star">
                </div>
              </div>
            </div>

            <span class="_w-max _pc-display-none _mb-mar-bottom-20">
              <span>订单号: {{ featured[featured_index].ordersn }}</span>
              <span class="_pc-pad-left-20 _mb-pad-left-60">路线: {{ featured[featured_index].area_express_name }}</span>
            </span>

            <div class="_line-4 _mb-mar-bottom-25" style="word-wrap: break-word ">
              {{ featured[featured_index].content }}
            </div>
            </div>
            <div class=" _flex-row-mid">
              <button class="btn _btn-p-hover _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;" @click="featured_index>0?featured_index--:featured_index=featured.length-1">
                
                <img
                  class="_pc-w-20 _pc-mar-right-10 right-ico-02 _mb-w-30"
                  src="@/assets/image/right-ico-03.png"
                  style="transform: rotateY(180deg)"
                  alt=""
                />

                <img
                  class="_pc-w-20 _pc-mar-right-10 right-ico-hover _mb-w-30"
                  src="@/assets/image/right-ico-02.png"
                  style="transform: rotateY(180deg)"
                  alt=""
                />
                上一个
              </button>
              <button class="btn _btn-p-hover  _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;" @click="featured_index<featured.length-1?featured_index++:featured_index=0">
                下一个
                <img
                  class="_pc-w-20 _pc-mar-left-10 right-ico-02 _mb-w-30"
                  src="@/assets/image/right-ico-03.png"
                  alt=""
                />

                <img
                  class="_pc-w-20 _pc-mar-left-10 right-ico-hover _mb-w-30"
                  src="@/assets/image/right-ico-02.png"
                  alt=""
                />
                </button>
            </div>
          </div>


          <!-- 占位 -->
          <div class="_flex-1 _pc-pad-y-20 _mb-pad-y-30 _pc-pad-x-50 mb-pad-x-25 _flex-col-bet" style="background-color: #f7f7f7;position: relative;" v-if="featured==''">
            <div class="_flex-col">
              <div class="_flex-row-mid _pc-mar-bottom-20 _mb-pad-bottom-5 _mb-display-block"><div class="_mb-flex-row-mid _mb-mar-bottom-20"><img class="_pc-w-60 _mb-w-60 _border-circle" style="" src="" alt=""><span class="_pc-font-26 _mb-font-32 _mb-mar-left-20 _pc-display-none"></span></div>
              <div class="_pc-mar-left-20 _mb-mar-left-0 _pc-font-16 _mb-font-24 _flex-col-center"><span class="_pc-font-26 _pc-pad-bottom-10 _mb-pad-bottom-5 _mb-display-none"></span><span class="_w-max _mb-display-none"><span>订单号: </span><span class="_pc-pad-left-20 _mb-pad-left-60">路线: </span></span></div>
            </div>
            <div class="_pc-font-16 _mb-font-24 _flex-wrap _flex-row _pc-mar-y-10 _mb-mar-bottom-30 comm-bg _mb-pad-y-30">
              <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid" ><div class="_mb-w-max _text-center _mb-mar-bottom-20">客服服务:</div> <div class="_mb-flex-row-mid"><img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index"  :src="stars_f" :alt="item_star"></div></div>
              <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid" ><div class="_mb-w-max _text-center _mb-mar-bottom-20">物流速度:</div><div class="_mb-flex-row-mid"><img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="stars_f" :alt="item_star"></div></div>
              <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid" ><div class="_mb-w-max _text-center _mb-mar-bottom-20">包裹完整:</div><div class="_mb-flex-row-mid"><img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="stars_f" :alt="item_star"></div></div></div>
              <span class="_w-max _pc-display-none _mb-mar-bottom-20"><span>订单号: </span><span class="_pc-pad-left-20 _mb-pad-left-60">路线: </span></span>
              <div class="_line-4 _mb-mar-bottom-25" style="word-wrap: break-word "></div>
            </div>
            <div class=" _flex-row-mid">
              <button class="btn _btn-p-hover _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;" @click="featured_index>0?featured_index--:featured_index=featured.length-1">
                <img class="_pc-w-20 _pc-mar-right-10 right-ico-02 _mb-w-30" src="@/assets/image/right-ico-03.png" style="transform: rotateY(180deg)" alt=""/><img  class="_pc-w-20 _pc-mar-right-10 right-ico-hover _mb-w-30" src="@/assets/image/right-ico-02.png" style="transform: rotateY(180deg)" alt=""/>上一个
              </button>
              <button class="btn _btn-p-hover  _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;" @click="featured_index<featured.length-1?featured_index++:featured_index=0">
                下一个<img class="_pc-w-20 _pc-mar-left-10 right-ico-02 _mb-w-30" src="@/assets/image/right-ico-03.png" alt=""/><img class="_pc-w-20 _pc-mar-left-10 right-ico-hover _mb-w-30" src="@/assets/image/right-ico-02.png" alt=""/>
              </button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!--   全部评价 -->
    <div class="_w-max wow fadeInLeft" style="background-color:#f7f7f7;">
      <div class="_w-max _pc-pad-x-260 _pc-mar-y-70 _mb-mar-y-60 _mb-pad-x-30">
        <!--      title标题-->
        <div class="msg-left-title _pc-mar-bottom-35 _mb-mar-bottom-30 _pc-font-16 _flex-row-end">
          <div class="_pc-font-42 _mb-font-52" style="color:var(--theme-color-title);font-weight: 800;line-height: 0.9;">EVALUATION</div><div class="_pc-font-28 _mb-font-36 _pc-pad-left-20 _mb-pad-left-25" style="font-weight: 800;">全部评价</div>
        </div>

         <!-- 全部评价列表-->
        <div class="_flex-row  _flex-wrap _mb-display-block">
          <div v-for="(item,index) in data.list" :key="index" class="_pc-w-430 _mb-w-max _pc-mar-y-40 _pc-pad-x-25 _pc-pad-bottom-20 _pc-mar-right-25 _mb-pad-x-30  _mb-pad-y-40 _mb-mar-bottom-30   _mb-mar-right-0 _pos-rel"  style="background-color: #fff;">
            <!--用户信息-->
            <div class="_flex-row-end _mb-flex-row-mid _pos-rel _avar-d-5 _mb-mar-bottom-25" style="">
              <img style="background-color: #efefef;border-radius: 50%;" class="_pc-w-95 _avar-h _mb-w-45" :src="item.headimgurl" alt="">
              <div class="_pc-mar-left-25 _mb-mar-left-20 ">
                <div class="_flex-row-end _pc-pad-top-20 _mb-pad-top-5">
                  <div :title="item.nickname" class="_pc-pad-right-10 _pc-font-22 _mb-font-32 _line-1 _pc-w-100 _mb-w-max" style="font-weight: 600;">{{ item.nickname }}</div>
                  <span class="_pc-w-170  _line-1 _mb-display-none" style="color: var(--theme-color);" :title="item.area_express_name">{{ item.area_express_name}}</span>
                </div>
                <div class="_mb-display-none">{{item.ordersn?'订单号:':'&nbsp;' }} {{ item.ordersn }}</div>
              </div>
            </div>

            <div class="_pc-display-none _mb-mar-bottom-15 _flex-row-mid _mb-font-24">
              <div class="_mb-w-050 _line-1">订单号:{{ item.ordersn }}</div>
              <div class="_mb-w-050 _line-1">路线:{{ item.area_express_name}}</div>
            </div>

            <!--评论星星-->
            <div class="_flex-col _mb-flex-row-mid _flex-wrap _mb-mar-bottom-30">
              <div class="_pc-mar-bottom-10 _mb-mar-bottom-5 _mb-w-050 _mb-mar-bottom-15">客户服务: <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="star_index<item.service_score?stars_t:stars_f" :alt="item_star"></div>
              <div class="_pc-mar-bottom-10 _mb-mar-bottom-5 _mb-w-050 _mb-mar-bottom-15">物流速度: <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="star_index<item.express_score?stars_t:stars_f" :alt="item_star"></div>
              <div class="_pc-mar-bottom-10 _mb-mar-bottom-5 _mb-w-050">物流速度: <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star,star_index) in 5" :key="star_index" :src="star_index<item.pack_score?stars_t:stars_f" :alt="item_star"></div>
            </div>
              <!--内容-->
            <div class="_min-h-80 comm-content _mb-mar-bottom-30" style="">
              {{item.content}}
            </div>
            <div class="_flex-row-mid _pc-mar-top-25 _mb-mar-top-5">
              <img v-viewer class="_pc-h-90 _pc-w-90 _mb-w-150  _pc-mar-x-20" style="background-color: #efeded;cursor: pointer;" v-for="(images_item,index) in item.images" :src="images_item" alt="1" v-show="index<4" :key="index">
            </div>
          </div>
        </div>
        <!--分页-->
        <div class="_flex-row-center-mid _w-max _mb-display-none">
            <button @click="prePage" class="_btn-p-hover _pc-h-25 _border-radius-25 _pc-pad-x-30 _pc-mar-x-30 commbtn _pc-font-16"  >上一页</button>
            <div :class="page.currentPage==item?point_page:'_pc-mar-x-15 _pc-w-25 _pc-h-25 _mb-h-5 _flex-row-mid _flex-row-center'" v-for="(item,index) in page.pageNum" :key="index" @click="getPageList(item)" style="cursor: pointer;">{{ item }}</div>
            <button @click="nextPage" class="_btn-p-hover   _pc-h-25 _border-radius-25 _pc-pad-x-30 _pc-mar-x-30 _pc-font-16 commbtn" >下一页</button>
        </div>

        <div class=" _flex-row-center-mid _pc-display-none">
          <button @click="prePage" class="btn _btn-p-hover _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-50 _flex-row-center-mid _pc-font-16 _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;" >
            <img class="_pc-w-20 _pc-mar-right-10 right-ico-02 _mb-w-30" src="@/assets/image/right-ico-03.png" style="transform: rotateY(180deg)" alt=""/><img  class="_pc-w-20 _pc-mar-right-10 right-ico-hover _mb-w-30" src="@/assets/image/right-ico-02.png" style="transform: rotateY(180deg)" alt=""/>上一个
          </button>
          <button @click="nextPage" class="btn _btn-p-hover  _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;">
            下一个<img class="_pc-w-20 _pc-mar-left-10 right-ico-02 _mb-w-30" src="@/assets/image/right-ico-03.png" alt=""/><img class="_pc-w-20 _pc-mar-left-10 right-ico-hover _mb-w-30" src="@/assets/image/right-ico-02.png" alt=""/>
          </button>
        </div>
      </div>
    </div>
<!--  集运动态  -->
    <div>
      <news-list></news-list>
    </div>
    
  </div>
</template>

<script>
import NewsList from "@/pages/avaluation/components/newsList";
export default {
name: "evaluationIndex",
  components: {NewsList},
  data() {
    return {
      
      pc_avaluation_banner:'',
      featured_index:0,
      stars_t:require('@/assets/image/stars1.png'),
      stars_f:require('@/assets/image/stars2.png'),
      data:{},
      featured:[],
      page:{
        pageNum:1,
        pageSize:6,//每页条数
        currentPage:1 //页码
      },
      point_page:'point_page _pc-mar-x-15 _pc-w-25 _pc-h-25 _mb-h-5'
    }
  },
 
  async asyncData(){
    return {
      stars_t:require('@/assets/image/stars1.png'),
      stars_f:require('@/assets/image/stars2.png'),
      data:{
        // 全部评论
        list:[
          {

          },
        ],

        count:'',
        //精选评论
        featured:[
          {

          }
        ]
      },
      featured:[
        {}
      ],
    }
  },
  mounted() {
    this.getList()
    setTimeout(()=>{
      new  this.$wow.WOW().init()
    },20)
  },
  methods:{
    //获取评论信息
    async getList(){
      let data = {
        page:this.page.currentPage,
        limit:this.page.pageSize
      }
      await this.$axios.get(
        'site/get_site_comment',
        {
          params:data
        }
      ).then(res => {
        this.data=res.data.data
        this.pc_avaluation_banner = this.data.avaluation_banner
        this.pageNum1();
        let featured=[];
        for (let i=0 ;i<res.data.data.featured.length;i++){
          if(res.data.data.featured[i].is_hot==1){
            featured.push(res.data.data.featured[i])
          }
        }
        
        this.featured = featured;
      })
    },
    //  页数
    pageNum1(){
      let page = Math.ceil(this.data.count / this.page.pageSize) || 1;
      this.page.pageNum = page;
    },
    getPageList(index){
      this.page.currentPage = index;
      this.getList()
    },
    nextPage(){
      if(this.page.currentPage===this.page.pageNum){
        return ''
      }
      this.page.currentPage = ++this.page.currentPage;
      this.getList()
    },
    prePage(){
      if(this.page.currentPage===1){
        return ''
      }
      this.page.currentPage = --this.page.currentPage;
      this.getList()
    }
  }
}
</script>

<style scoped>
*{
  color: #333333;
}
.msg-left-title,
.featured-left-title{
  position: relative;
}
.msg-left-title:after{
  content: 'ALL EVALUATION';
  position: absolute;
  color: #aaaaaa;
  letter-spacing: 4px;
  top: 0;
  right: 0;
}
.featured-left-title:after{
  content: 'FEATURED REVIEWS';
  position: absolute;
  color: #aaaaaa;
  letter-spacing: 4px;
  top: 0;
  right: 0;
}
.btn {
  border: 1px solid var(--theme-color-bg);
  color: var(--theme-color);
  background-color: #fff;

}
/* .btn:hover {
   content: '';
   background-color: rgba(226,31,25,0.1);
  cursor: pointer;
} */
.point_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: var(--theme-color-bg);
  color: #FFF;
  border-radius: 50%;
}
._btn-p-hover:hover{background: var(--theme-color-bg); color:var(--theme-color);}
.commbtn{border: 1px solid var(--theme-color-bg);color:var(--theme-color);cursor: pointer;}

._text-line-number_1 {
  line-height: 1.6;
  overflow: hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1;
}


._btn-p-hover{color: var(--theme-color-title);}
._btn-p-hover:hover{color:#FFF;}
.right-ico-hover{display: block;}
.right-ico-02{display: none;}
._btn-p-hover:hover .right-ico-02{display: block;}
._btn-p-hover:hover .right-ico-hover{display: none;}
._min-h-80{height:3.6vh;}
.comm-content{word-wrap: break-word;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;line-height: 1.6}
._avar-d-5{top: -5%;}
._avar-h{height:4.6875vw;}
@media screen and (max-device-width: 768px) {
  .point_page{
    height: 0.88052vh;
    width: 0.88052vh;
  }
  ._min-h-80{height:unset;}
  .msg-left-title:after{content: '';}
  .featured-left-title:after{content: '';}
  .comm-bg{background: #d0d0d050;border-radius: 1.33333vw;}
  ._avar-d-5{top:unset;}
  ._avar-h{height:6vw;}
}
</style>
